<template>
  <div class="no-print">
    <v-list :nav="nav" :dense="dense" v-if="!subGroup">
      <template v-for="(menu, i) in value">
        <!-- menu.children -->
        <v-list-group
          v-if="!!menu.children && isShow(menu)"
          :key="i"
          v-model="menu.open"
          :prepend-icon="menu.icon"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ menu.text }}</v-list-item-title>
          </template>
          <Menu :value="menu.children" sub-group />
        </v-list-group>
        <!-- menu.item -->
        <v-list-item
          v-else-if="isShow(menu)"
          :key="i"
          :to="menu.to"
          @click="menu.click ? menu.click : ''"
        >
          <v-list-item-icon v-if="!!menu.icon">
            <v-icon>{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ menu.text }}</v-list-item-title>
        </v-list-item>
        <!-- menu End -->
      </template>
    </v-list>

    <!-- 再起処理用 -->
    <template v-else v-for="(menu, i) in value">
      <!-- menu.children -->
      <v-list-group
        v-if="!!menu.children && isShow(menu)"
        :key="i"
        v-model="menu.open"
        :sub-group="subGroup"
      >
        <template v-slot:activator v-if="isShow(menu)">
          <v-list-item-title>{{ menu.text }}</v-list-item-title>
        </template>
        <Menu :value="menu.children" sub-group />
      </v-list-group>
      <!-- menu.item -->
      <v-list-item
        v-else-if="isShow(menu)"
        :key="i"
        :to="menu.to"
        @click="menu.click ? menu.click : ''"
      >
        <v-list-item-icon>
          <v-icon>{{ menu.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ menu.text }}</v-list-item-title>
      </v-list-item>
      <!-- menu End -->
    </template>
  </div>
</template>

<script>
export default {
  name: "ListMenu",
  components: { Menu: () => import("./ListMenu") },

  props: {
    value: Array,
    nav: Boolean,
    dense: Boolean,
    subGroup: Boolean
  },
  methods: {
    isShow(menu) {
      console.log("isShow", menu);
      let authority = this.$store.state.authority;

      if (authority == undefined || authority == null || authority === "")
        authority = 0;

      console.log("authority", authority);
      //if (menu.authority > authority) return false;
      if (menu.authority.indexOf(Number(authority)) < 0) return false;

      return true;
    }
  }
};
</script>

<style></style>
